import { Component, OnInit } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { UxHelpersService } from 'src/app/services/ux-helpers.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {
  pages:any=[
    {
      title:'Dashboard',
      url:'/app/dashboard',
      icon:'speedometer',
      color:'primary'
    },
    {
      title:'Settings',
      icon:'settings',
      color:'warning',
      moduleName:'settings',
      children:[
        {
          title:'Salary Incentive',
          url:'/app/settings/salary-incentives',
          icon:'cash',
          color:'danger',
          moduleName:'settings',
          actionName:"settings-salaryincentiveview"
        },
        {
          title:'Curling',
          url:'/app/settings/curling',
          icon:'cash',
          color:'medium',
          // moduleName:'settings',
          // actionName:"settings-salaryincentiveview"
        },
        {
          title:'Husk Purchase',
          url:'/app/settings/purchase',
          icon:'bag-add',
          color:'tertiary',
          // moduleName:'settings',
          // actionName:"settings-salaryincentiveview"
        },
        {
          title:'Meter Reading',
          url:'/app/settings/meter-reading',
          icon:'speedometer',
          color:'secondary',
          // moduleName:'settings',
          // actionName:"settings-salaryincentiveview"
        }
      ]
    },
    {
      title:'Day / Shift',
      url:'/app/day-open-close',
      icon:'today',
      color:'success',
      moduleName:'dayandshift',
      actionName:'dayandshift-view',
    },
    {
      title:'Work Allocation',
      url:'/app/work-allocation/view',
      icon:'today',
      color:'success'
    },
    {
      title:'Day Close',
      url:'/app/day-open-close/dayclose',
      icon:'today',
      color:'danger',
      moduleName:'dayandshift',
      actionName:'dayandshift-view',
    },
    {
      title:'Masters',
      icon:'home',
      color:'success',
      moduleName:'transporters',
      children:[
        {
          title:'Husk Bale Transporter',
          url:'/app/masters/bale-transporters',
          iconURL:'assets/icon/bale.svg',
          color:'primary',
          moduleName:'transporters',
          actionName:"transporters-view"
        },
        {
          title:'Loose Husk Transporter',
          url:'/app/masters/loose-husk-transporters',
          iconURL:'assets/icon/truck-moving-solid.svg',
          color:'danger',
        },{
          title:'Own Vehicle',
          url:'/app/masters/own-vehicles',
          iconURL:'assets/icon/truck-solid.svg',
          color:'secondary',
        },
        {
          title:'Contract Team Workers',
          url:'/app/masters/contract-team-workers',
          iconURL:'assets/icon/people-carry-solid.svg',
          color:'warning',
        },
        {
          title:'Yard',
          url:'/app/masters/yard',
          icon:'storefront',
          color:'tertiary',
        },
        {
          title:'Bunk',
          url:'/app/masters/petrol-bunk',
          iconURL:'assets/icon/gas-pump-solid.svg',
          color:'success',
        },
        {
          title:'Machineries',
          url:'/app/masters/machineries',
          icon:'construct',
          color:'primary',
        },{
          title:'Pith Stock Yards',
          url:'/app/masters/pith-stock-yards',
          icon:'storefront',
          color:'secondary',
        },
      ]
    },
    {
      title:'Purchase',
      icon:'cart',
      color:'medium',
      moduleName:'purchases',
      url:'/app/purchases'
    },
    {
      title:'Purchase',
      icon:'cart',
      color:'secondary',
      moduleName:'purchases',
      children:[
        {
          title:'Bale Purchase',
          url:'/app/purchases/mysore-bale-delivery',
          icon:'cart',
          // iconURL:'assets/icon/people-carry-solid.svg',
          color:'primary',
          moduleName:'purchases',
          actionName:'purchases-add',
        },
        {
          title:'Fuel Purchase',
          url:'/app/purchases/fuel',
          iconURL:'assets/icon/gas-pump-solid.svg',
          color:'success'
        }
      ]
    },{
      title:'Salary',
      icon:'cart',
      color:'secondary',
      moduleName:'purchases',
      children:[
        {
          title:'Weekly Wages',
          icon:'ellipse-outline',
          // iconURL:'assets/icon/people-carry-solid.svg',
          color:'primary',
          children:[
              {
                title:'Pre-Pay Report',
                url:'/app/salary/weekly-local-labours/pre-pay-report',
                icon:'remove-outline',
                color:'tertiary',
              },
              {
                title:'Post-Pay Report',
                url:'/app/salary/weekly-local-labours/post-pay-report',
                icon:'remove-outline',
                color:'tertiary',
              },
              {
                title:'Payment',
                url:'/app/salary/weekly-local-labours',
                icon:'remove-outline',
                color:'tertiary',
              },
            ]
        },
        {
          title:'Weekly OS Inc.',
          icon:'ellipse-outline',
          // iconURL:'assets/icon/people-carry-solid.svg',
          color:'primary',
          children:[
              {
                title:'Pre-Pay Report',
                url:'/app/salary/weekly-other-labours/pre-pay-report',
                icon:'remove-outline',
                color:'tertiary',
              },
              {
                title:'Post-Pay Report',
                url:'/app/salary/weekly-other-labours/post-pay-report',
                icon:'remove-outline',
                color:'tertiary',
              },
              {
                title:'Payment',
                url:'/app/salary/weekly-other-labours',
                icon:'remove-outline',
                color:'tertiary',
              },
            ]
        }
      ]
    },
    {
      title:'Payments',
      // url:'/app/purchases',
      icon:'cash',
      color:'danger',
      moduleName:'purchases',
      actionName:'purchases-payment',
      children:[
        {
          title:'Bale Transporters',
          icon:'checkmark',
          color:'secondary',
          moduleName:'purchases',
          actionName:'purchases-payment',
          children:[
            {
              title:'Hire Payment Approval',
              url:'/app/payments/bale-transporter-hire-payment-approval',
              icon:'checkmark',
              color:'primary',
              moduleName:'purchases',
              actionName:'purchases-payment',
            },
            {
              title:'Hire Payment',
              url:'/app/payments/bale-transporter-hire-payment',
              icon:'cash',
              color:'tertiary',
              moduleName:'purchases',
              actionName:'purchases-payment',
            },
            {
              title:'Deposit Payment',
              url:'/app/payments/bale-transporter-deposit-payment',
              icon:'card',
              color:'success',
              moduleName:'purchases',
              actionName:'purchases-payment',
            }
          ]
        },
        {
          title:'Bunk',
          url:'/app/payments/bunk-payment',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'wallet',
          color:'danger',
        },{
          title:'Weekly  Local Labours Payment',
          url:'/app/payments/weekly-local-labours',
          icon:'wallet',
          color:'warning',
        },{
          title:'Weekly  Other State Labours Payment',
          url:'/app/payments/weekly-other-state-labours',
          icon:'wallet',
          color:'warning',
        },{
          title:'Advance Payment',
          url:'/app/payments/local-labours-advance-payment',
          icon:'wallet',
          color:'warning',
        },{
          title:'Addition / Deduction Payment',
          url:'/app/payments/local-labours-adjustment-payment',
          icon:'wallet',
          color:'warning',
        },{
          title:'Incentive Payment',
          url:'/app/payments/incentive-payment',
          icon:'wallet',
          color:'warning',
        },{
          title:'Agent Payment',
          url:'/app/payments/agent-payment',
          icon:'wallet',
          color:'warning',
        },
      ]
    },
    {
      title:'Production',
      // url:'/app/purchases',
      icon:'construct',
      color:'primary',
      children:[
        {
          title:'Fuel Consumption',
          url:'/app/production/fuel-consumption',
          iconURL:'assets/icon/gas-pump-solid.svg',
          // icon:'checkmark',
          color:'success',
        },
        {
          title:'Beater Buster',
          url:'/app/production/beater-buster',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'checkmark',
          color:'tertiary',
        },{
          title:'Pith Bag Issue',
          url:'/app/production/pith-bag-issue',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'checkmark',
          color:'tertiary',
        },{
          title:'Pith Bag Transport',
          url:'/app/production/pith-pag-transport',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'checkmark',
          color:'tertiary',
        }
      ]
    },
    {
      title:'Reports',
      icon:'document-text',
      color:'medium',
      children:[
        {
          title:'Ledger Report',
          // url:'#',
          icon:'cash',
          color:'primary',
          children:[
              {
                title:'Bale Transporter Hire Ledger Report',
                url:'/app/reports/bale-transporter-hire-ledger',
                icon:'remove-outline',
                color:'tertiary',
              },
              {
                title:'Bale Transporter Deposit Leadger Report',
                url:'/app/reports/bale-transporter-deposit',
                icon:'card',
                color:'warning',
              },
          ]
        },
        {
          title:'Bale Delivery Report',
          url:'/app/reports/delivery',
          iconURL:'assets/icon/bale.svg',
          color:'medium',
        },
        {
          title:'Bunk',
          url:'/app/reports/bunk-leadger',
          iconURL:'assets/icon/gas-pump-solid.svg',
          color:'tertiary',
        },
        {
          title:'Contract Team Workers',
          url:'/app/reports/contract-team-workers-ledger',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'people',
          color:'success',
        },
        {
          title:'Day Close',
          url:'/app/reports/day-close-report',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'calendar',
          color:'danger',
        },
        {
          title:'Attendance Report',
          icon:'document-text',
          color:'success',
          children:[
            {
              title:'Other State Labours Report',
              url:'/app/reports/attendance-other-state-labour',
              // iconURL:'assets/icon/gas-pump-solid.svg',
              icon:'document-text',
              color:'warning',
            },{
              title:'Local Labours Weekly Report',
              url:'/app/reports/attendance-weekly-local-labour',
              // iconURL:'assets/icon/gas-pump-solid.svg',
              icon:'document-text',
              color:'tertiary',
            }
          ]
        },
        {
          title:'Employee Incentive Ledger',
          url:'/app/reports/employee-incentive-ledger',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'document-text',
          color:'tertiary',
        },{
          title:'Agent Ledger',
          url:'/app/reports/agent-ledger',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'document-text',
          color:'tertiary',
        },{
          title:'Employee Salary Ledger',
          url:'/app/reports/employee-salary-ledger',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'document-text',
          color:'tertiary',
        },{
          title:'Pith Bag Issue',
          url:'/app/reports/pith-bag-issue',
          // iconURL:'assets/icon/gas-pump-solid.svg',
          icon:'document-text',
          color:'tertiary',
        }
      ]
    },
    {
      title:'Employee',
      url:'/app/employees',
      icon:'people-circle',
      color:'secondary',
      moduleName:'employee',
      actionName:'employee-view'
    },
    {
      title:'Agent',
      url:'/app/masters/agents',
      icon:'people',
      color:'success',
      moduleName:'employeeagent',
      actionName:'employeeagent-view'
    },
    {
      title:'Users',
      url:'/app/users',
      icon:'people',
      color:'warning',
      moduleName:'users',
      actionName:'users-view'
    }
  ]
  buildVersion=environment.buildVersion;
  constructor(public auth:AuthService,private loadingController: LoadingController,
    private api:ApiService, private help:UxHelpersService, private navController:NavController,
    private storage:Storage) { }

  ngOnInit() {
    if(true){
      this.pages=[
        {
          title:'Dashboard',
          url:'/app/dashboard',
          icon:'speedometer',
          color:'primary'
        },
        {
          title:'Production',
          url:'/app/production',
          icon:'today',
          color:'warning',
          isMulti:true,
          moduleName:['dayandshift','attendance','deliveryinvoice','pithbagcollection','reports','mastermachineries',
          'yard','settings','bedassignment'],
          actionName:['dayandshift-view','attendance-view','deliveryinvoice-deliveryview','pithbagcollection-issue',
          'pithbagloading-add','reports-dayclose','reports-pithbagcollection','reports-pithbagloading',
          'mastermachineries-view','yard-view','settings-dayopenmodesview','bedassignment-view','settings-pithbagcollectionview'],
        },{
          title:'Hire',
          url:'/app/menu/hire',
          // icon:'today',
          iconURL:'assets/icon/truck-solid.svg',
          color:'success',
          isMulti:true,
          moduleName:['hireprovider','hireroutes','reports','payments'],
          actionName:['hireprovider-view','hireroutes-view','reports-ledgerhireprovider',
          'reports-hire',"payments-hireprovider","hireprovider-monthlysettlementapprovalview",
          "reports-hiremonthlysettlement", "hireprovider-entry"],
        },{
          title:'Sales',
          url:'/app/menu/sales',
          icon:'bag',
          // iconURL:'assets/icon/truck-solid.svg',
          color:'danger',
          isMulti:true,
          moduleName:['customer','reports','deliveryinvoice','settings'],
          actionName:['customer-view','reports-ledgercustomer','settings-invoiceview',
          'deliveryinvoice-invoiceview','deliveryinvoice-onholdinvoiceview','settings-commodityview'],
        },{
          title:'Customer Payment Receipt',
          url:'/app/payments/customer',
          icon:'cash',
          // iconURL:'assets/icon/truck-solid.svg',
          color:'primary',
          isMulti:true,
          moduleName:['customer'],
          actionName:['customer-paymentReceipt'],
        },{
          title:'Staff Management',
          url:'/app/menu/staff-menu',
          icon:'people',
          color:'danger',
          isMulti:true,
          moduleName:['salary','reports','payments','employee','mastercontractteamworkers','settings'],
          actionName:['salary-weeklylocalprepay','salary-weeklylocalpostpay','salary-weeklylocalpayment','payments-labouradvance',
          'reports-locallabourattendance','reports-ledgeremployeesalary','employee-view','settings-salaryincentiveview','salary-wagegeneration']
        }, 
        {
          title:'Users',
          url:'/app/users',
          icon:'people',
          color:'warning',
          moduleName:'users',
          actionName:'users-view'
        }
      ]
    }
  }
  async logOut(){
    this.api.setToken(null);
    this.auth.setLoginStatus(false);
    this.storage.clear();
    this.help.presentToast('Log-out Successfully...');
    this.navController.navigateRoot(['/login']);
  }
}