import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';

@Injectable({
providedIn: 'root'
})
export class UxHelpersService {

  constructor(private loadingController: LoadingController, 
    private toastController: ToastController,
    private alertController: AlertController) { }
  
  async presentToast(msg,duration=2000) {
    const toast = await this.toastController.create({
      message: msg,
      duration: duration
    });
    toast.present();
  }

  async presentLoading(message,duration=2000,spinner:"bubbles" | "circles" | "circular" | "crescent" | "dots" | "lines" | "lines-small"='bubbles',disableDuration:boolean=false) {
    var options:LoadingOptions={};
    options.message=message;
    if(!disableDuration)
    options.duration=duration;
    options.spinner=spinner;
    const loading = await this.loadingController.create(options);
    await loading.present();
    return loading;
  }

  async presentAlert(message:string='',header:string='Alert',buttontext:string='OK') {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: [buttontext]
    });
    await alert.present();
  }
  async presentAlertWsub(message:string='',header:string='Alert',subheader:string='',buttontext:string='OK') {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: ['buttontext']
    });
    await alert.present();
  }
  async presentAlertConfirm(alertobject:{}) {
    const alert = await this.alertController.create(alertobject);
  
    await alert.present();
  }
}
