import { Directive } from '@angular/core';
import { Validator, NG_VALIDATORS, ValidatorFn, FormControl } from '@angular/forms';
import { AppHelperService } from '../services/app-helper.service';

@Directive({
  selector: '[appQuantity]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useClass: QuantityDirective,
      multi: true
    }
  ]
})
export class QuantityDirective {
  validator: ValidatorFn;
  maxQty=0;
  shiftInfo;
  constructor(private app:AppHelperService) {
    this.shiftInfo=this.app.dayDetails.currentShift=='A'?this.app.dayDetails.shiftAinfo:this.app.dayDetails.shiftBinfo;
    this.maxQty= this.shiftInfo.openingInfo['stocks'].pithBlock;
    this.validator = this.qtyValidator();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }
  qtyValidator(): ValidatorFn {
    return (control: FormControl) => {
      if (control.value != null) {
        console.log(control.value, this.maxQty)
        if(this.shiftInfo['shiftMode']=='fullFunctional'||this.shiftInfo['shiftMode']=='production'){
          if(control.value > this.maxQty ){
            return null;
          }
        }else{
          if(control.value > this.maxQty ){
            return {
              qtyValidator: { valid: false }
            };
          }
        }
      } else {
        return null;
      }
    };
  }
}
